import React from 'react';
import EntityForm from '../../../EntityManagement/EntityForm/EntityForm';
import IUser from '../../../../services/User/IUser';
import SectionHeading from '../../../../components/Heading/SectionHeading/SectionHeading';
import UserEntityStrings from '../../UserEntityStrings';
import useUserNotificationsFormConfig from './useUserNotificationsFormConfig';
import UserKioskAlertPreferences from './UserKioskAlerts/UserKioskAlertsPreferences';

interface IUserNotifications {}

const UserNotifications: React.FC<IUserNotifications> = () => {
  const formConfig = useUserNotificationsFormConfig();

  return (
    <>
      <SectionHeading heading={UserEntityStrings.NotificationPreferencesHeading} />
      <EntityForm<IUser> mode='edit' formConfig={formConfig}>
        <UserKioskAlertPreferences />
      </EntityForm>
    </>
  );
};
export default UserNotifications;
