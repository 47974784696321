import { TextField, Checkbox, FormControlLabel, MenuItem, Typography } from '@material-ui/core';
import EnumSelectInput from '../Input/EnumSelectInput';
import formatFieldName from '../../../utils/formatFieldName';
import { FieldConfig, FieldKeys } from './FieldConfig';
import CheckboxList from '../Input/CheckboxList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ThemeConfig from '../../../theme/ThemeConfig';
import FieldLabel from './FieldLabel/FieldLabel';

interface IFieldRenderer<T> {
  field: FieldConfig<T>;
  value: any;
  onChange: (key: FieldKeys<T>, value: any) => void;
  disabled?: boolean;
  labelOverride?: string;
  inputProps?: {
    step?: string;
  };
  error?: string;
  layout?: 'row' | 'list';
}

const FieldRenderer = <T,>({
  field,
  value,
  onChange,
  disabled,
  labelOverride,
  inputProps,
  error,
  layout
}: IFieldRenderer<T>) => {
  if (field.type === 'enum' && field.enumValues) {
    return (
      <EnumSelectInput
        value={value}
        onChange={(val) => onChange(field.key, val)}
        enumValues={field.enumValues}
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        disabled={disabled}
        required={field.required}
        error={error}
      />
    );
  }

  if (field.type === 'boolean') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={!!value}
            onChange={(e) => onChange(field.key, e.target.checked)}
            name={String(field.key)}
            color='default'
            checkedIcon={<CheckBoxIcon fontSize='medium' />}
            icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
          />
        }
        label={
          <>
            <Typography style={{ fontWeight: ThemeConfig.fontWeights.bold }}>
              {labelOverride ? labelOverride : formatFieldName(String(field.key))}
            </Typography>
            {field.description && <>{field.description}</>}
          </>
        }
        style={{ color: '#333', paddingBottom: '8px' }}
        disabled={disabled}
      />
    );
  }

  if (field.type === 'number') {
    return (
      <TextField
        size='small'
        variant='outlined'
        required={field.required}
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        value={value === undefined ? '' : value}
        onChange={(e) => onChange(field.key, +e.target.value)}
        style={{ margin: '10px 0' }}
        inputProps={{ step: inputProps?.step ?? '0.01', style: { padding: '0 10px' } }}
        type='number'
        disabled={disabled}
        error={!!error}
        helperText={error || ''}
      />
    );
  }

  if (field.type === 'select' && field.options) {
    return (
      <>
        <FieldLabel label={labelOverride ? labelOverride : formatFieldName(String(field.key))} />
        <TextField
          select
          size='small'
          variant='outlined'
          value={value}
          onChange={(e) => onChange(field.key, e.target.value)}
          style={{ margin: '10px 0', width: 'auto' }}
          disabled={disabled}
          required={field.required}
          error={!!error}
          helperText={error || ''}
        >
          {field.options.map((option, key) => (
            <MenuItem key={key} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  }

  if (field.type === 'password') {
    return (
      <TextField
        size='small'
        variant='outlined'
        required={field.required}
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        type='password'
        value={value ?? ''}
        onChange={(e) => onChange(field.key, e.target.value)}
        style={{ margin: '10px 0' }}
        disabled={disabled}
        error={!!error}
        helperText={error || ''}
      />
    );
  }

  if (field.type === 'checkbox-list') {
    return (
      <CheckboxList
        items={
          field.options
            ?.filter((option) => option.label)
            ?.sort((a, b) => a.label!.localeCompare(b.label!))
            ?.map((option) => ({
              label: option.label!,
              value: option.value!
            })) ?? []
        }
        onFilterClick={(value) => onChange(field.key, value)}
        checkedItems={value ?? ''}
        layout={layout}
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        description={field.description}
      />
    );
  }

  return (
    <TextField
      size='small'
      variant='outlined'
      required={field.required}
      label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
      value={value || ''}
      onChange={(e) => onChange(field.key, e.target.value)}
      style={{ margin: '10px 0' }}
      type='text'
      disabled={disabled}
      inputProps={{ style: { padding: '0 10px' } }}
      error={!!error}
      helperText={error || ''}
    />
  );
};

export default FieldRenderer;
