import { useState, useEffect, useCallback } from 'react';
import EntityDataAccessService from './EntityDataAccessService';
import { useHistory } from 'react-router-dom';

interface IUseEntityData<T> {
  entity: T | null;
  entities: T[];
  loading: boolean;
  error: string | null;
  fetchEntity: () => Promise<void>;
  fetchEntities: (options?: unknown) => Promise<void>;
  updateEntity: (data: Partial<T>) => Promise<boolean>;
  deleteEntity?: (id: keyof T) => Promise<void>;
  deleteEntities?: (idList: Array<keyof T>) => Promise<Array<keyof T>>;
  createEntity: (data: Partial<T>, redirectOnSubmitPath?: string) => Promise<T | undefined>;
}

export function useEntityData<T>(service: EntityDataAccessService<T>, entityId?: string): IUseEntityData<T> {
  const [entity, setEntity] = useState<T | null>(null);
  const [entities, setEntities] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const fetchEntity = useCallback(async () => {
    if (!entityId) {
      setLoading(false);
      setEntity(null);
      return;
    }

    if (entity) {
      // Entity already exists in state, skipping fetch
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const data = await service.getEntityById(entityId);
      setEntity(data);
    } catch (err) {
      setError(err instanceof Error ? `Error retrieving entity: ${err.message}` : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, [entityId, entity, service]);

  const updateEntity = useCallback(
    async (data: Partial<T>): Promise<boolean> => {
      setLoading(true);
      setError(null);
      try {
        const updatedEntity = await service.updateEntity({ ...entity, ...data });
        setEntity(updatedEntity);
        return true;
      } catch (err) {
        setError(err instanceof Error ? `Error updating entity: ${err.message}` : 'An unknown error occurred');
        return false;
      } finally {
        setLoading(false);
      }
    },
    [service, entity]
  );

  const createEntity = useCallback(
    async (data: Partial<T>, redirectOnSubmitPath?: string) => {
      if (data) {
        setLoading(true);
        setError(null);
        try {
          const response = await service.createEntity(data);
          const id = (response as { _id: string })._id;
          if (id && redirectOnSubmitPath) {
            history.push(`/${redirectOnSubmitPath}/${id}/edit`);
          }
          return response;
        } catch (err) {
          console.error('Error creating entity:', err);
          setError(err instanceof Error ? `Error creating entity: ${err.message}` : 'An unknown error occurred');
        } finally {
          setLoading(false);
        }
      }
    },
    [history, service]
  );

  const fetchEntities = useCallback(
    async (options?: unknown) => {
      setLoading(true);
      setError(null);
      try {
        const data = await service.getAllEntities(options ?? {});
        setEntities(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    },
    [service]
  );

  const deleteEntities = useCallback(
    async (idsToDelete: Array<keyof T>): Promise<Array<keyof T>> => {
      if (idsToDelete && service.deleteEntities) {
        setLoading(true);
        setError(null);
        try {
          await service.deleteEntities(idsToDelete);
          return idsToDelete;
        } catch (err) {
          console.error('Error deleting entities:', err);
          setError(err instanceof Error ? `Error deleting entities: ${err.message}` : 'An unknown error occurred');
        } finally {
          setLoading(false);
        }
      }
      return [];
    },
    [service]
  );

  useEffect(() => {
    fetchEntity();
  }, [fetchEntity]);

  return { entity, loading, error, fetchEntity, updateEntity, createEntity, fetchEntities, entities, deleteEntities };
}
