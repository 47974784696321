import moment from 'moment';

const allowedTimezones = [
  { zone: 'America/Los_Angeles', name: 'US Pacific Time' },
  { zone: 'America/Denver', name: 'US Mountain Time' },
  { zone: 'America/Chicago', name: 'US Central Time' },
  { zone: 'America/New_York', name: 'US Eastern Time' }
];

const timezones = allowedTimezones.map(({ zone, name }) => {
  const offset = moment.tz(zone).utcOffset() / 60;
  const formattedOffset = `(GMT${offset >= 0 ? `+${offset}` : offset})`;
  return {
    label: `${formattedOffset} ${name}`,
    value: zone
  };
});

export default timezones;
