import { useAppSelector } from '../../../../hooks/storeHooks';
import { FieldConfig } from '../../../../components/Form/FormBuilder/FieldConfig';
import IUser from '../../../../services/User/IUser';
import { CustomerFacingNotificationMedium } from '../../../../services/Notifications/NotificationMedium';
import UserEntityStrings from '../../UserEntityStrings';
import HoursOfDay from '../../../../common/HourOfDay';
import DayOfWeek from '../../../../common/DayOfWeek';
import timezones from '../../../../utils/timezones';
import FormConfig from '../../../../components/Form/FormBuilder/FormConfig';
import FieldGroupConfig from '../../../../components/Form/FormBuilder/FieldGroupConfig';

const ENABLE_DO_NOT_DISTURB: boolean = false;

const ENABLE_KIOSK_ORDER_SUMMARY: boolean = false;

const useUserNotificationsFormConfig = () => {
  const loggedInUser = useAppSelector((store) => store.auth?.user);

  const groups: FieldGroupConfig[] = [
    ...(ENABLE_KIOSK_ORDER_SUMMARY ? [{ id: 'kioskOrderSummary' }] : []),
    ...(ENABLE_DO_NOT_DISTURB ? [{ id: 'doNotDisturbSchedule' }] : [])
  ];

  const timeZoneField: FieldConfig<IUser> = {
    key: 'timezone',
    label: UserEntityStrings.TimezoneLabel,
    type: 'select',
    required: true,
    options: timezones
  };

  const kioskOrderSummaryFields: FieldConfig<IUser>[] = [
    {
      key: 'notificationPreferences.kioskOrderSummary.enabled',
      groupId: 'kioskOrderSummary',
      type: 'boolean',
      required: false,
      label: 'Receive Weekly Order Summary Reports',
      description: `Receive weekly order reports sent to your email address: ${loggedInUser.email}`
    },
    {
      key: 'notificationPreferences.kioskOrderSummary.hourOfDay',
      groupId: 'kioskOrderSummary',
      label: UserEntityStrings.HourOfDayLabel,
      type: 'select',
      required: false,
      options: Object.values(HoursOfDay).map((day, index) => ({ label: day, value: index })),
      renderCondition: (user) => user?.notificationPreferences?.kioskOrderSummary?.enabled === true
    },
    {
      key: 'notificationPreferences.kioskOrderSummary.dayOfWeek',
      groupId: 'kioskOrderSummary',
      label: UserEntityStrings.DayOfWeekLabel,
      type: 'select',
      required: false,
      options: Object.values(DayOfWeek).map((day) => ({ label: day, value: day })),
      renderCondition: (user) => user?.notificationPreferences?.kioskOrderSummary?.enabled === true
    }
  ];

  const doNotDisturbScheduleFields: FieldConfig<IUser>[] = [
    {
      key: 'notificationPreferences.doNotDisturbSchedule.enabled',
      groupId: 'doNotDisturbSchedule',
      type: 'boolean',
      required: false,
      label: 'Enable Do Not Disturb',
      description: 'Turn on do not disturb mode for selected mediums and time range.'
    },
    {
      key: 'notificationPreferences.doNotDisturbSchedule.days',
      groupId: 'doNotDisturbSchedule',
      type: 'checkbox-list',
      required: false,
      layout: 'list',
      label: 'Do Not Disturb Days',
      options: Object.values(DayOfWeek).map((day) => ({
        label: day,
        value: day
      })),
      renderCondition: (user) => user?.notificationPreferences?.doNotDisturbSchedule?.enabled === true,
      description: 'Select days to not receive notifications'
    },
    {
      key: 'notificationPreferences.doNotDisturbSchedule.startHour',
      groupId: 'doNotDisturbSchedule',
      type: 'select',
      required: false,
      label: 'Do Not Disturb Start Time',
      options: Object.values(HoursOfDay).map((hour, index) => ({ label: hour, value: index })),
      renderCondition: (user) => user?.notificationPreferences?.doNotDisturbSchedule?.enabled === true
    },
    {
      key: 'notificationPreferences.doNotDisturbSchedule.endHour',
      groupId: 'doNotDisturbSchedule',
      type: 'select',
      required: false,
      label: 'Do Not Disturb End Time',
      options: Object.values(HoursOfDay).map((hour, index) => ({ label: hour, value: index })),
      renderCondition: (user) => user?.notificationPreferences?.doNotDisturbSchedule?.enabled === true
    },
    {
      key: 'notificationPreferences.doNotDisturbSchedule.mediums',
      groupId: 'doNotDisturbSchedule',
      type: 'checkbox-list',
      required: false,
      layout: 'row',
      description: 'Notification types you do not want to receive when do not disturb is enabled',
      label: 'Notification Types',
      options: Object.values([CustomerFacingNotificationMedium.Email, CustomerFacingNotificationMedium.SMS]).map(
        (medium) => ({ label: medium?.toLocaleUpperCase(), value: medium })
      ),
      renderCondition: (user) => user?.notificationPreferences?.doNotDisturbSchedule?.enabled === true
    }
  ];

  const formConfig: FormConfig<IUser> = {
    fields: [
      timeZoneField,
      ...(ENABLE_KIOSK_ORDER_SUMMARY ? kioskOrderSummaryFields : []),
      ...(ENABLE_DO_NOT_DISTURB ? doNotDisturbScheduleFields : [])
    ],
    groups
  };

  return formConfig;
};

export default useUserNotificationsFormConfig;
