import { useCallback, useEffect, useState, useRef } from 'react';
import SubmitButton from '../../Button/SubmitButton';
import styles from './ChatMessageInput.module.css';
import { ChatStatus } from '../ChatWidget/ChatWidget';
import ChatStrings from '../ChatStrings';
import IconButton from '../../Button/IconButton';
import { Settings } from '@material-ui/icons';
import ChatMessageConfigurationReport from '../ChatMessageConfiguration/ChatMessageConfigurationReport';
import ChatMessageConfigurationControl from '../ChatMessageConfiguration/ChatMessageConfigurationControl';
import IconLoading from '../../ui/Icons/IconLoading';
import NotificationConstants from '../../../common/Notifications/NotificationConstants';
import UserContactInfo from '../../../common/Notifications/UserContactInfo';
import NotificationMedium from '../../../services/Notifications/NotificationMedium';
import NewMessageRequest from '../../../common/Notifications/NewMessageRequest';

interface IChatMessageInput {
  onSendMessage: (message: string, recipients: NewMessageRequest[]) => void;
  status: ChatStatus;
  participants: UserContactInfo[];
  messageControls?: React.ReactNode;
}

export interface IMessageConfig {
  recipients: NewMessageRequest[];
  displayMessageConfiguration: boolean;
}

const ChatMessageInput: React.FC<IChatMessageInput> = ({ onSendMessage, status, participants, messageControls }) => {
  const [message, setMessage] = useState('');
  const [config, setConfig] = useState<IMessageConfig>({
    recipients: [],
    displayMessageConfiguration: false
  });

  const handleSendMessage = (message: string, recipients: NewMessageRequest[]) => {
    if (message) {
      onSendMessage(message, recipients);
      setMessage('');
    }
  };

  const statusLabel: Record<ChatStatus, string> = {
    [ChatStatus.Ready]: ChatStrings.SendMessageButtonReady,
    [ChatStatus.Sending]: ChatStrings.SendMessageButtonSending,
    [ChatStatus.Error]: ChatStrings.SendMessageButtonError,
    [ChatStatus.Initializing]: ChatStrings.SendMessageButtonInit
  };

  const handleToggleMessageConfigurationControl = () => {
    setConfig((prev) => ({
      ...prev,
      displayMessageConfiguration: !config.displayMessageConfiguration
    }));
  };

  const handleRecipientChange = useCallback((updatedRecipients: NewMessageRequest[]) => {
    setConfig((prev) => ({ ...prev, recipients: updatedRecipients }));
  }, []);

  const recipientsInitialized = useRef(false);

  useEffect(() => {
    if (!recipientsInitialized.current && participants?.length > 0) {
      setConfig((prev) => ({
        ...prev,
        recipients: participants.map((userDetails) => ({
          userDetails,
          sendMediums: [
            ...(userDetails.email &&
            userDetails?.optedInNotificationMediums &&
            userDetails?.optedInNotificationMediums.includes(NotificationMedium.Email)
              ? [NotificationMedium.Email]
              : []),
            ...(userDetails.phone_number &&
            userDetails?.optedInNotificationMediums &&
            userDetails?.optedInNotificationMediums.includes(NotificationMedium.SMS)
              ? [NotificationMedium.SMS]
              : [])
          ]
        }))
      }));
      recipientsInitialized.current = true;
    }
  }, [participants]);

  const messageLength = message.length;
  const smsList = config.recipients.filter((r) => r.sendMediums.includes(NotificationMedium.SMS));

  const messageLengthUnacceptable = () => {
    const { characterLimit, systemGeneratedCharactersCount } = NotificationConstants.SMS;
    return smsList.length > 0 && messageLength > characterLimit - systemGeneratedCharactersCount;
  };

  const shouldDisable =
    [ChatStatus.Initializing, ChatStatus.Sending].includes(status) ||
    message.length === 0 ||
    messageLengthUnacceptable();

  const smsMessageWarning = (): string | undefined => {
    const { characterLimit, systemGeneratedCharactersCount } = NotificationConstants.SMS;
    const charLimitLessPrefix = characterLimit - systemGeneratedCharactersCount;
    const charsOverMaxLimit = messageLength - charLimitLessPrefix;
    if (charsOverMaxLimit > 0 && messageLengthUnacceptable()) {
      return `${charsOverMaxLimit} character${
        charsOverMaxLimit > 1 ? 's' : ''
      } over SMS character limit (${charLimitLessPrefix})`;
    }
    return undefined;
  };

  const smsMessageInfo = () => {
    const { messageSplitCharacterThreshold, maxMessages, systemGeneratedCharactersCount } = NotificationConstants.SMS;

    if (messageLength === 0) return smsList.length > 0 ? `(0/${maxMessages})` : undefined;

    const additionalCharacters = messageLength - (messageSplitCharacterThreshold - systemGeneratedCharactersCount);
    const currMessageCount =
      additionalCharacters > 0 ? 1 + Math.ceil(additionalCharacters / messageSplitCharacterThreshold) : 1;
    return smsList.length > 0 ? `(${currMessageCount}/${maxMessages})` : undefined;
  };

  return (
    <div className={styles.ChatMessageInput}>
      <div className={styles.configureMessageRow}>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={ChatStrings.MessageInputPlaceholder}
        />
        <div className={styles.configureOptions}>
          <IconButton
            onClick={handleToggleMessageConfigurationControl}
            label={ChatStrings.MessageInputConfigIconAria}
            icon={<Settings />}
          />
        </div>
      </div>
      <span>
        {ChatStrings.MessageInputCharLengthPrefix} {messageLength.toLocaleString() ?? 0}{' '}
        {ChatStrings.MessageInputCharLengthSuffix}
      </span>
      <ChatMessageConfigurationReport
        config={config}
        warningMessage={{ sms: smsMessageWarning() }}
        infoMessage={{ sms: smsMessageInfo() }}
      />
      <div className={styles.submitRow}>
        {messageControls && messageControls}
        <div className={styles.submitButton}>
          {[ChatStatus.Initializing, ChatStatus.Sending].includes(status) && <IconLoading width={20} height={10} />}
          <SubmitButton
            label={statusLabel[status]}
            disabled={shouldDisable}
            onClick={() => handleSendMessage(message, config.recipients)}
          />
        </div>
      </div>
      <ChatMessageConfigurationControl
        display={config.displayMessageConfiguration}
        participants={participants}
        selectedRecipients={config.recipients}
        onRecipientChange={handleRecipientChange}
        onClose={handleToggleMessageConfigurationControl}
      />
    </div>
  );
};

export default ChatMessageInput;
