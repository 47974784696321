import React from 'react';
import EntityEditScreen from '../../EntityManagement/EntityEdit/EntityEditScreen';
import CompanyService from '../../../services/Company/CompanyService';
import EntityForm from '../../EntityManagement/EntityForm/EntityForm';
import UsersList from '../../Users/UsersList';
import EntityType from '../../EntityManagement/EntityType';
import KiosksList from '../../Kiosk/KiosksList/KiosksList';
import { useCompanyFormState } from '../CompanyForm/useCompanyFormState';

interface ICompanyEditScreen {
  companyId: string;
}

const CompanyEdit: React.FC<ICompanyEditScreen> = ({ companyId }) => {
  const { formConfig, handleFieldChange, handleBeforeStateUpdate } = useCompanyFormState();

  const CompanyEditTabsConfig = (companyId: string) => [
    {
      label: 'General',
      key: 'general',
      component: <EntityForm mode='edit' formConfig={formConfig} onFieldChange={handleFieldChange} />
    },
    { label: 'Users', key: 'users', component: <UsersList companyId={companyId} heading='Users' /> },
    {
      label: 'Kiosks',
      key: 'kiosks',
      component: <KiosksList companyId={companyId} excludeKeys={['company', 'operator']} heading='Kiosks' />
    }
  ];

  return (
    <EntityEditScreen
      entityId={companyId}
      tabConfig={CompanyEditTabsConfig(companyId)}
      service={new CompanyService()}
      entityType={EntityType.Company}
      headingKey={'name'}
      displayBackButton
      onBeforeStateUpdate={handleBeforeStateUpdate}
    />
  );
};

export default CompanyEdit;
