import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import FieldConfigUtility from '../../../components/Form/FormBuilder/FieldConfigUtility';
import FormConfig from '../../../components/Form/FormBuilder/FormConfig';
import ICompany from '../../../services/Company/ICompany';
import IOperator from '../../../services/Operators/IOperator';
import IOrganization from '../../../services/Organizations/IOrganization';

export const CompanyGeneralFieldConfig = ({
  operators,
  organizations
}: {
  operators?: IOperator[];
  organizations?: IOrganization[];
}): FormConfig<ICompany> => {
  const groups = [];

  const fields: FieldConfig<ICompany>[] = [
    { key: 'name', required: true, type: 'string' },
    { key: 'address', required: false, type: 'string' },
    { key: 'city', required: false, type: 'string' },
    { key: 'state', required: false, type: 'string' }
  ];

  if (operators && operators.length > 0)
    fields.push({
      key: 'operator',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOperator>('_id', 'name', operators)
    });

  if (organizations && organizations.length > 0)
    fields.push({
      key: 'organization',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOperator>('_id', 'name', organizations)
    });

  return { fields, groups };
};
