import KioskType from '../../../../../common/Kiosk/KioskType';
import NotificationEvent from '../../../../../services/Notifications/NotificationEvent';
import NotificationMedium from '../../../../../services/Notifications/NotificationMedium';

export type CustomerFacingNotificationEvent = {
  label: string;
  event: NotificationEvent;
  kioskTypes: KioskType[];
  description?: string;
};

export const customerFacingNotificationEvents = (kioskType: KioskType): CustomerFacingNotificationEvent[] =>
  [
    {
      label: '"Low Bottles" Inventory Alerts',
      event: NotificationEvent.LowBottleInventory,
      description: 'Notifies when the bottle inventory is low.',
      kioskTypes: [KioskType.Duo]
    },
    {
      label: '"No Bottles" Inventory Alerts',
      event: NotificationEvent.NoBottleInventory,
      description: 'Notifies when there are no bottles left in inventory, requiring restocking.',
      kioskTypes: [KioskType.Duo]
    },
    {
      label: '"Low Additives" Inventory Alerts',
      event: NotificationEvent.LowAdditiveInventory,
      description: 'Notifies when the additives inventory (e.g., flavors) is low.',
      kioskTypes: []
    },
    {
      label: '"No Additives" Inventory Alerts',
      event: NotificationEvent.NoAdditiveInventory,
      description: 'Notifies when no additives (e.g., flavors) are left in inventory, requiring restocking.',
      kioskTypes: []
    },
    {
      label: 'General Warnings',
      event: NotificationEvent.Warning,
      description: 'General system warnings that notify about non-critical issues or recommended actions.',
      kioskTypes: []
    },
    {
      label: 'General Errors & Critical Incident Alerts',
      event: NotificationEvent.ERROR,
      description: 'Critical system errors & incidents which can contain recommended actions for resolution.',
      kioskTypes: []
    }
  ].filter(({ kioskTypes }) => kioskTypes.length === 0 || kioskTypes.includes(kioskType));

export type CustomerFacingNotification = NotificationEvent;

export type NotificationPreference = Record<CustomerFacingNotification, NotificationMedium[]>;

export type KioskEventNotificationPreferences = Record<string, NotificationPreference>;

export default KioskEventNotificationPreferences;
