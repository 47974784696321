enum IncidentReportStrings {
  MissingFromReport = 'Missing from report',
  Heading = 'Incident Report',
  StatusReportHeading = 'Status Report',
  CustomerFacingErrorHeading = 'Customer Facing Error',
  EventNotificationHeading = 'Event Notification',
  DetailsHeading = 'Details',
  ErrorFetchingReports = 'Failed to fetch Incident Report',
  TechnicalErrorHeading = 'Technical Error',
  TechnicalCauseMissing = 'Technical cause not provided',
  QueryDataHeading = 'Peripherals Query Data',
  IdentityData = 'Peripheral Identities',
  ErrorUpdatingIncident = 'Failed to update incident',
  ChatWidgetHeading = 'Message Users',
  ChatWidgetDescription = 'Send messages to users affected by this Incident',
  UsersNotified = 'Users Notified'
}

export default IncidentReportStrings;
