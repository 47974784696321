import React, { useCallback, useEffect, useState } from 'react';
import NotificationMediumIcon from '../../../../../components/Chat/NotificationMediumIcon/NotificationMediumIcon';
import NotificationMedium from '../../../../../services/Notifications/NotificationMedium';
import styles from './KioskAlertPreferenceReport.module.css';
import { KioskLink } from '../../../../../components/Link/KioskLink';
import UserEntityStrings from '../../../UserEntityStrings';
import HealthStatusIndicator from '../../../../../components/ui/Icons/HealthStatusIndicator/HealthStatusIndicator';

interface IKioskAlertPreferenceReport {
  kioskId: string;
  kioskAlias: string | undefined;
  location: string | undefined;
  preferenceReport: {
    eventsWithNotificationsCount: number;
    notificationMediums: NotificationMedium[];
    maxPossibleEvents: number;
  };
  onConfigure: (kioskId: string) => void;
}

enum HealthStatus {
  Healthy = 'healthy',
  Unhealthy = 'unhealthy',
  Neutral = 'neutral',
  Warning = 'warning'
}

const KioskAlertPreferenceReport: React.FC<IKioskAlertPreferenceReport> = ({
  kioskId,
  kioskAlias,
  location,
  onConfigure,
  preferenceReport
}) => {
  const { eventsWithNotificationsCount, notificationMediums, maxPossibleEvents } = preferenceReport;
  const [status, setStatus] = useState<HealthStatus>(HealthStatus.Neutral);

  const assignHealthStatus = useCallback((): HealthStatus => {
    if (eventsWithNotificationsCount === 0 && maxPossibleEvents > 0) return HealthStatus.Unhealthy;
    if (eventsWithNotificationsCount < maxPossibleEvents) return HealthStatus.Warning;
    return HealthStatus.Healthy;
  }, [eventsWithNotificationsCount, maxPossibleEvents]);

  useEffect(() => {
    setStatus(assignHealthStatus());
  }, [assignHealthStatus, eventsWithNotificationsCount, maxPossibleEvents]);

  const highlightStyle: Record<HealthStatus, string> = {
    [HealthStatus.Healthy]: '',
    [HealthStatus.Unhealthy]: styles.critical,
    [HealthStatus.Warning]: styles.warning,
    [HealthStatus.Neutral]: ''
  };

  const statusMessage: Record<HealthStatus, string | null> = {
    [HealthStatus.Healthy]: null,
    [HealthStatus.Unhealthy]: UserEntityStrings.KioskAlertsHealthUnhealthy,
    [HealthStatus.Warning]: UserEntityStrings.KioskAlertsHealthWarning,
    [HealthStatus.Neutral]: null
  };

  return (
    <div className={[styles.container, highlightStyle[status]].join(' ')} onClick={() => onConfigure(kioskId)}>
      <div>
        <div className={styles.kioskDetails}>
          <KioskLink id={kioskId} alias={kioskAlias} />
        </div>
        <div className={styles.location}>{location}</div>
      </div>
      <div className={styles.notificationInfo}>
        <div className={styles.notificationSummary}>
          <strong>{eventsWithNotificationsCount}</strong> / <strong>{maxPossibleEvents} </strong>
          {UserEntityStrings.KioskAlertSingleLabel}
          {maxPossibleEvents !== 1 && 's'}
        </div>
        <div className={styles.notificationIcons}>
          {notificationMediums
            ?.sort((a, b) => a.localeCompare(b))
            .map((medium) => (
              <NotificationMediumIcon key={`${kioskId}-${medium}`} medium={medium} />
            ))}
        </div>
      </div>
      {statusMessage[status] && (
        <div className={styles.statusMessage}>
          <HealthStatusIndicator status={status} />
          <p>{statusMessage[status]}</p>
        </div>
      )}
    </div>
  );
};

export default KioskAlertPreferenceReport;
