import { Checkbox, ListItem, ListItemText, List } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import styles from './CheckboxList.module.css';
import FieldLabel from '../FormBuilder/FieldLabel/FieldLabel';

interface ICheckboxList {
  items: { label: string; value: string | number }[];
  onFilterClick: (text: string) => void;
  checkedItems: string[];
  layout?: 'row' | 'list';
  label?: string;
  description?: string;
}

const CheckboxList: React.FC<ICheckboxList> = ({
  items,
  onFilterClick,
  checkedItems,
  layout = 'list',
  label,
  description
}) => {
  const isCheckBoxChecked = (text: string, arr: string[]) => arr.includes(text);

  return (
    <>
      {label && <FieldLabel label={label} />}
      {description && <p>{description}</p>}
      <List className={layout === 'row' ? styles.row : styles.list}>
        {items.map((item) => (
          <ListItem key={item.value} onClick={() => onFilterClick(String(item.value))} className={styles.listItem}>
            <Checkbox
              color='default'
              checkedIcon={<CheckBoxIcon fontSize='medium' />}
              icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
              inputProps={{ 'aria-label': 'decorative checkbox' }}
              checked={isCheckBoxChecked(String(item.value), checkedItems)}
            />
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default CheckboxList;
