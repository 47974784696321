import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import FieldConfigUtility from '../../../components/Form/FormBuilder/FieldConfigUtility';
import FormConfig from '../../../components/Form/FormBuilder/FormConfig';
import IOperator from '../../../services/Operators/IOperator';
import IOrganization from '../../../services/Organizations/IOrganization';

const fieldConfig = (operators?: IOperator[]): FieldConfig<IOrganization>[] => {
  const fields: FieldConfig<IOrganization>[] = [
    { key: 'name', required: true, type: 'string' },
    { key: 'address', required: true, type: 'string' },
    { key: 'city', required: true, type: 'string' },
    { key: 'state', required: true, type: 'string' }
  ];
  if (operators && operators.length > 0)
    fields.push({
      key: 'operator',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOperator>('_id', 'name', operators)
    });
  return fields;
};

const OrganizationGeneralFormConfig = (operators?: IOperator[]): FormConfig<IOperator> => ({
  fields: fieldConfig(operators),
  groups: []
});

export default OrganizationGeneralFormConfig;
