import PeripheralIdentifier from '../../../common/Peripherals/PeripheralIdentifier';
import { localDateTimeString } from '../../../utils/dateUtility';
import IncidentReportData from './IncidentReportData';
import IncidentReportStrings from './IncidentReportStrings';

type ReportSection = { title: string; dataPath: () => unknown };

export default class IncidentReportHelper {
  static generatePeripheralQueryDataSection = (data: IncidentReportData): ReportSection => {
    const formattedPeripheralsQueryData = Object.entries(PeripheralIdentifier)
      .filter(([key]) => isNaN(Number(key)))
      .reduce((acc, [enumKey, identifier]) => {
        const queryData = data.peripheralsQueryData?.[identifier as keyof typeof PeripheralIdentifier];
        if (queryData) {
          acc[enumKey] = queryData;
        }
        return acc;
      }, {} as Record<string, unknown>);

    return {
      title: IncidentReportStrings.QueryDataHeading,
      dataPath: () => formattedPeripheralsQueryData
    };
  };

  static generatePeripheralIdentityDataSection = (data: IncidentReportData): ReportSection => {
    const formattedIdentityData = Object.entries(PeripheralIdentifier)
      .filter(([key]) => isNaN(Number(key)))
      .reduce((acc, [enumKey, identifier]) => {
        const identityData = data.status?.peripheralIdentities?.[identifier as keyof typeof PeripheralIdentifier];
        if (identityData) {
          acc[enumKey] = identityData;
        }
        return acc;
      }, {} as Record<string, unknown>);

    return {
      title: IncidentReportStrings.IdentityData,
      dataPath: () => formattedIdentityData
    };
  };

  static dateString = (date?: string): string => {
    if (!date) return '';
    return localDateTimeString(date);
  };

  static getReportSections = (data: IncidentReportData): ReportSection[] => [
    {
      title: IncidentReportStrings.DetailsHeading,
      dataPath: () => ({
        kioskId: data?.kioskId ?? '',
        kioskStatus: data?.status?.kioskStatus ?? '',
        incidentId: data?.id ?? '',
        resolution: data?.resolution ?? '',
        correlationId: data?.correlationId ?? '',
        appVersion: data?.status?.appVersion ?? '',
        incidentDate: { convertedToYourTime: data?.date ? this.dateString(data?.date) : '', UTC: data?.date ?? '' }
      })
    },
    {
      title: IncidentReportStrings.UsersNotified,
      dataPath: () => ({
        ...Object.fromEntries(
          data?.usersNotified?.map((user, index) => [
            `User ${index + 1}`,
            `${user.name ?? ''} ${user?.email ?? ''} ${user?.phone_number ?? ''}`
          ]) ?? []
        )
      })
    },
    {
      title: IncidentReportStrings.EventNotificationHeading,
      dataPath: () => data?.eventNotification
    },
    {
      title: IncidentReportStrings.CustomerFacingErrorHeading,
      dataPath: () => ({
        name: data?.error?.name,
        message: data?.error?.userFacingMessage ?? data?.error?.message
      })
    },
    {
      title: IncidentReportStrings.TechnicalErrorHeading,
      dataPath: () => data?.error?.cause ?? { cause: IncidentReportStrings.MissingFromReport }
    },
    {
      title: IncidentReportStrings.StatusReportHeading,
      dataPath: () => ({
        features: data?.status?.featuresState ?? IncidentReportStrings.MissingFromReport,
        paymentIsConfigured: data?.status?.paymentIsConfigured ?? IncidentReportStrings.MissingFromReport,
        bottleInGarbage: data?.status?.bottleInGarbage ?? IncidentReportStrings.MissingFromReport,
        bottleStaged: data?.status?.bottleStaged ?? IncidentReportStrings.MissingFromReport,
        waterInBottle: data?.status?.waterInBottle ?? IncidentReportStrings.MissingFromReport,
        sequencesInProgress:
          data?.status?.orchestratorState?.sequencesInProgress ?? IncidentReportStrings.MissingFromReport
      })
    },
    IncidentReportHelper.generatePeripheralQueryDataSection(data),
    IncidentReportHelper.generatePeripheralIdentityDataSection(data),
    {
      title: 'Usage Report',
      dataPath: () => data?.status?.usage ?? {}
    }
  ];
}
