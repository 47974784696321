import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import FormConfig from '../../../components/Form/FormBuilder/FormConfig';
import IOperator from '../../../services/Operators/IOperator';

const operatorGeneralFieldConfig: FieldConfig<IOperator>[] = [
  { key: 'name', required: true, type: 'string' },
  { key: 'address', required: true, type: 'string' },
  { key: 'city', required: true, type: 'string' },
  { key: 'state', required: true, type: 'string' }
];

const OperatorFormConfig: FormConfig<IOperator> = {
  fields: operatorGeneralFieldConfig,
  groups: []
};

export default OperatorFormConfig;
