import Theme, { ThemeColors, ThemeFontSizes, ThemeFontWeights } from './Theme';

const themeColors: ThemeColors = {
  primary: [21, 72, 98, 1],
  secondary: [159, 159, 159, 1],
  tertiary: [23, 71, 97, 1],
  alert: [249, 60, 18, 1],
  highlight: [212, 184, 102, 1],
  success: [34, 139, 34, 1],
  error: [249, 60, 18, 1],
  warning: [255, 166, 0, 1],
  primaryLighter: [77, 171, 234, 1],
  primaryLight: [35, 107, 144, 1],
  white: [255, 255, 255, 1],
  black: [0, 0, 0, 0.87]
};

const fontSizes: ThemeFontSizes = {
  base: '14px',
  sm: '25px',
  md: '32px',
  lg: '35px',
  xl: '40px'
};

const fontWeights: ThemeFontWeights = {
  light: 300,
  base: 500,
  medium: 500,
  bold: 600
};

const ThemeConfig: Theme = {
  colors: themeColors,
  fontFamily: `'Open Sans', sans-serif`,
  fontSizes,
  fontWeights
};

export default ThemeConfig;
