enum UserEntityStrings {
  KioskSelectDescription = 'Assign kiosks to user. Change role to Machine Super Admin if user should access all kiosks in company.',
  NotificationPreferencesHeading = 'Notification Preferences',
  ReportsOrdersTitle = 'Weekly Order Summary Reports',
  ReportsOrdersDescription = 'Receive weekly order reports sent to your email address',
  DayOfWeekLabel = 'Day of week to receive report',
  DayOfWeekDescription = 'Day of week to receive report',
  HourOfDayLabel = 'Hour of day to receive report',
  TimezoneLabel = 'Your Timezone',
  DashboardAccessTitle = 'Dashboard Access',
  KioskAccessTitle = 'Kiosk Maintenance Access',
  PermissionsTitle = 'Permissions',
  KioskNotificationsTitle = 'Kiosk Notifications & Alerts',
  KioskNotificationsDescription = 'Real-time notifications and alerts about your kiosk(s)',
  NotificationEventTitle = 'Events To Notify You About',
  NotificationEventDescription = 'Select the events you wish to be notified about',
  NotificationMediumsTitle = 'How Can We Notify You?',
  KioskAlertsTitle = 'Kiosk Alerts',
  KioskAlertsDescription = 'Configure the kiosks you want to be notified about, the notification methods you prefer (e.g., Email, SMS), and the specific events you want to receive alerts for.',
  KioskAlertSingleLabel = 'Alert',
  KioskAlertsHealthUnhealthy = 'No alerts will send',
  KioskAlertsHealthWarning = 'Some alerts will send but not all',
  FailedPhoneNumberValidation = 'Invalid phone number format. Please enter your phone number in E.164 format (e.g., +15555555555 for a US phone number). Ensure the number includes a country code (+1 for US), starts with a "+", and contains no spaces or special characters.'
}

export default UserEntityStrings;
