import is from 'check-types';
import { useState } from 'react';
import Dialog from '../../../../../components/Dialog/Dialog';
import NotificationMedium, {
  CustomerFacingNotificationMedium
} from '../../../../../services/Notifications/NotificationMedium';
import { customerFacingNotificationEvents } from './KioskEventNotificationPreferences';
import EntityForm from '../../../../EntityManagement/EntityForm/EntityForm';
import IUser from '../../../../../services/User/IUser';
import { FieldConfig } from '../../../../../components/Form/FormBuilder/FieldConfig';
import SectionHeading from '../../../../../components/Heading/SectionHeading/SectionHeading';
import useKiosksData from '../../../../../services/Kiosk/useKiosks';
import { useEntityManagementContext } from '../../../../EntityManagement/context/useEntityManagementContext';
import KioskAlertPreferenceReport from './KioskAlertPreferenceReport';
import UserEntityStrings from '../../../UserEntityStrings';
import styles from './UserKioskAlertsPreferences.module.css';
import KioskType from '../../../../../common/Kiosk/KioskType';

interface IUserKiosksAlertPreferences {}

const UserKioskAlertPreferences: React.FC<IUserKiosksAlertPreferences> = () => {
  const [selectedKiosk, setSelectedKiosk] = useState<{ id: string; type: KioskType } | null>(null);
  const kiosks = useKiosksData({ companyOnlyKiosks: false });

  const { state } = useEntityManagementContext<IUser>();

  const preferences = state.formState?.notificationPreferences;

  const generateKioskEventFields = (kioskId: string, kioskType: KioskType): FieldConfig<IUser>[] => {
    return customerFacingNotificationEvents(kioskType).map(({ label, event, description }) => ({
      key: `notificationPreferences.kioskEvents.${kioskId}.${event}`,
      type: 'checkbox-list',
      layout: 'row',
      required: false,
      label: `Receive ${label}`,
      description,
      options: Object.values([CustomerFacingNotificationMedium.Email, CustomerFacingNotificationMedium.SMS]).map(
        (medium) => ({ label: medium?.toLocaleUpperCase(), value: medium })
      )
    }));
  };

  const kioskName = (_id: string) => {
    const doc = kiosks?.find((kiosk) => kiosk._id === _id);
    return `${doc?.kiosk_ID} ${doc?.alias && doc?.alias !== doc.kiosk_ID ? `(${doc?.alias})` : ''}`;
  };

  const eventsWithNotifications = (
    kioskDocId: string,
    kioskType: KioskType
  ): { eventsWithNotificationsCount: number; notificationMediums: NotificationMedium[]; maxPossibleEvents: number } => {
    const kioskConfig = preferences?.kioskEvents?.[kioskDocId];
    const maxPossibleEventCount = customerFacingNotificationEvents(kioskType).length;
    if (!kioskConfig) {
      return { eventsWithNotificationsCount: 0, notificationMediums: [], maxPossibleEvents: maxPossibleEventCount };
    }

    let notificationMediums: NotificationMedium[] = [];

    const eventsWithNotificationsCount = Object.entries(kioskConfig).reduce((total, [_event, mediums]) => {
      if (mediums.length > 0) {
        notificationMediums = [...notificationMediums, ...mediums];
        return total + 1;
      }
      return total;
    }, 0);

    return {
      eventsWithNotificationsCount,
      notificationMediums: Array.from(new Set(notificationMediums)),
      maxPossibleEvents: maxPossibleEventCount
    };
  };

  if (kiosks?.length === 0) return null;

  return (
    <>
      <SectionHeading level={3} heading={UserEntityStrings.KioskAlertsTitle} />
      <p className={styles.description}>{UserEntityStrings.KioskAlertsDescription}</p>
      <div>
        {kiosks
          ?.filter((kiosk) => !is.undefined(kiosk?.settings?.installInfo?.kioskType))
          ?.sort((a, b) => a.kiosk_ID.localeCompare(b.kiosk_ID))
          .map((kiosk) => (
            <KioskAlertPreferenceReport
              key={kiosk._id}
              kioskId={kiosk.kiosk_ID}
              kioskAlias={kiosk.alias}
              location={kiosk.location}
              preferenceReport={eventsWithNotifications(
                kiosk._id,
                kiosk.settings?.installInfo?.kioskType ?? KioskType.Refill
              )}
              onConfigure={() =>
                setSelectedKiosk({ id: kiosk._id, type: kiosk.settings?.installInfo?.kioskType ?? KioskType.Refill })
              }
            />
          ))}
      </div>
      {selectedKiosk && (
        <Dialog display={!!selectedKiosk} onClose={() => setSelectedKiosk(null)}>
          <SectionHeading
            level={5}
            heading={`${kioskName(selectedKiosk.id)} ${UserEntityStrings.KioskAlertSingleLabel}s`}
          />
          <EntityForm<IUser>
            mode='edit'
            formConfig={{
              fields: generateKioskEventFields(selectedKiosk.id, selectedKiosk.type),
              groups: []
            }}
            onSuccess={() => setSelectedKiosk(null)}
          />
        </Dialog>
      )}
    </>
  );
};

export default UserKioskAlertPreferences;
