import ThemeConfig from '../../../../theme/ThemeConfig';
import rgbaToRgbString from '../../../../utils/rgbaToRgbString';

interface IFieldLabel {
  label: string | number;
}

const FieldLabel: React.FC<IFieldLabel> = ({ label }) => {
  const { fontSizes, colors, fontWeights } = ThemeConfig;
  return (
    <label style={{ fontSize: fontSizes.base, color: rgbaToRgbString(colors.black), fontWeight: fontWeights.bold }}>
      {label}
    </label>
  );
};

export default FieldLabel;
