import PageHeading from '../../../components/Heading/PageHeading';
import { useEntityState } from '../context/useEntityState';
import EntityDataAccessService from '../../../services/GenericEntity/EntityDataAccessService';
import { EntityManagementContextProvider } from '../context/EntityManagementContext';
import styles from './EntityCreate.module.css';
import FormConfig from '../../../components/Form/FormBuilder/FormConfig';
import EntityForm from '../EntityForm/EntityForm';
import EntityType from '../EntityType';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';

export interface IEntityCreateScreen<T> {
  formConfig: FormConfig<T>;
  service: EntityDataAccessService<T>;
  initialEntity?: Partial<T> | null;
  redirectOnSubmitPath?: string;
  onSuccess?: (entity: T | undefined) => unknown;
  entityType: EntityType;
  /**
   * Callback triggered whenever the form's state changes.
   * This can be used to respond to user input or to dynamically update the form's configuration.
   *
   * **Example use case**:
   * Update the list of organizations when the selected Operator changes.
   */
  onFieldChange?: (formState: Partial<T>) => void;
  /**
   * Optional hook to update entity state after a field change event but before the `formState` updates.
   *
   * Useful for modifying the temp state of the entity using other properties of T.
   */
  onBeforeStateUpdate?: (key: keyof T, value: any, entityState: T) => T;
}

const EntityCreate = <T,>({
  formConfig,
  service,
  initialEntity,
  redirectOnSubmitPath,
  onSuccess,
  entityType,
  onFieldChange,
  onBeforeStateUpdate
}: IEntityCreateScreen<T>) => {
  const entityManagementState = useEntityState<T>({ service, entityType, onBeforeStateUpdate });

  return (
    <div className={styles.EntityCreate}>
      <EntityManagementContextProvider value={{ ...entityManagementState, entityType }}>
        <PageHeading heading={`Create New ${stringWithSpacesUppercase(entityType)}`} />
        <EntityForm<T>
          formConfig={formConfig}
          mode='create'
          initialEntity={initialEntity}
          redirectOnSubmitPath={redirectOnSubmitPath}
          onSuccess={onSuccess}
          onFieldChange={onFieldChange}
        />
      </EntityManagementContextProvider>
    </div>
  );
};

export default EntityCreate;
