import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styles from './ChatMessageConfiguration.module.css';
import IconButton from '../../Button/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChatStrings from '../ChatStrings';
import UserContactInfo from '../../../common/Notifications/UserContactInfo';
import NewMessageRequest from '../../../common/Notifications/NewMessageRequest';
import NotificationMedium from '../../../services/Notifications/NotificationMedium';

interface IChatMessageConfigurationControl {
  participants: UserContactInfo[];
  selectedRecipients: NewMessageRequest[];
  onRecipientChange: (updatedRecipients: NewMessageRequest[]) => void;
  display: boolean;
  onClose: () => void;
}

const ChatMessageConfigurationControl: React.FC<IChatMessageConfigurationControl> = ({
  selectedRecipients,
  onRecipientChange,
  display,
  onClose
}) => {
  const handleCheckboxChange = (userDetails: UserContactInfo, medium: NotificationMedium) => {
    const selectedRequest = selectedRecipients.find((selected) => selected.userDetails.uuid === userDetails.uuid);

    const updatedSendMediums = selectedRequest?.sendMediums.includes(medium)
      ? selectedRequest.sendMediums.filter((m) => m !== medium)
      : [...(selectedRequest?.sendMediums || []), medium];

    const updatedSelected = selectedRecipients.map((selected) =>
      selected.userDetails.uuid === userDetails.uuid ? { ...selected, sendMediums: updatedSendMediums } : selected
    );

    if (!selectedRequest && updatedSendMediums.length > 0) {
      updatedSelected.push({ userDetails, sendMediums: updatedSendMediums });
    }

    onRecipientChange(updatedSelected);
  };

  const renderRecipientList = (medium: NotificationMedium, label: string) => (
    <div>
      <h6>{label}</h6>
      {selectedRecipients
        .filter(
          (r) =>
            !r.userDetails?.optedInNotificationMediums || r.userDetails.optedInNotificationMediums.includes(medium)
        )
        .map((recipient) => (
          <FormControlLabel
            key={`${recipient.userDetails.uuid}-${medium}`}
            control={
              <Checkbox
                checked={selectedRecipients.some(
                  (selected) =>
                    selected.userDetails.uuid === recipient.userDetails.uuid && selected.sendMediums.includes(medium)
                )}
                onChange={() => handleCheckboxChange(recipient.userDetails, medium)}
              />
            }
            label={`${recipient.userDetails.name} (${
              recipient.userDetails[medium === NotificationMedium.Email ? 'email' : 'phone_number'] || ''
            })`}
          />
        ))}
    </div>
  );

  return (
    <div className={`${styles.ChatMessageConfigurationControl} ${display ? styles.show : styles.hide}`}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h5>{ChatStrings.MessageConfigureHeading}</h5>
          <div className={styles.closeButton}>
            <IconButton onClick={onClose} icon={<CloseIcon />} label='Close' />
          </div>
        </div>
        <div className={styles.content}>
          {renderRecipientList(NotificationMedium.SMS, 'SMS Recipients')}
          {renderRecipientList(NotificationMedium.Email, 'Email Recipients')}
        </div>
      </div>
    </div>
  );
};

export default ChatMessageConfigurationControl;
