enum NotificationEvent {
  LowBottleInventory = 'Low Bottle Inventory Warning',
  LowAdditiveInventory = 'Low Additive Inventory Warning',
  NoBottleInventory = 'No Bottle Inventory Warning',
  NoAdditiveInventory = 'No Additive Inventory Warning',
  ERROR = 'Error',
  ORDER = 'Bottle Order',
  Warning = 'warning',
  SoftwareUpdate = 'Software Update',
  SoftwareError = 'software-error',
  UsageWarning = 'high-usage-warning',
  UsageZero = 'usage-zero-percent',
  FilterLifeSpanThreeMonth = 'filter-lifespan-three-months',
  FilterLifeSpanExpired = 'filter-lifespan-expired',
  SecurityEvent = 'Security Event',
  TestSms = 'test-sms',
  ExternalTankVolumeWarning = 'external-tank-volume-low',
  MachineActivity = 'machine-activity',
  IncidentUpdate = 'incident-update'
}

export default NotificationEvent;
