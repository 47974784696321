import IncidentReportData from '../../screens/Reports/IncidentReport/IncidentReportData';
import IncidentReportDataExcerpt from '../../screens/Reports/IncidentReport/IncidentReportDataExcerpt';
import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import IKioskHealthReport from './IKiosksHealthReport';
import INotification from '../Notifications/INotification';
import NotificationMedium from '../Notifications/NotificationMedium';
import ApiUtility from '../HttpClient/ApiUtility';
import NewMessageRequest from '../../common/Notifications/NewMessageRequest';

export default class ReportsService {
  static getKiosksHealthReport = async (): Promise<IKioskHealthReport[]> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const report = await apiService.request('reports/kiosk-health', 'GET');
    return report as unknown as IKioskHealthReport[];
  };

  static getIncidentReport = async (correlationId: string): Promise<IncidentReportData> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const report = await apiService.request(`reports/incidents/${correlationId}`, 'GET');
    return report as unknown as IncidentReportData;
  };

  static getIncidentReports = async (): Promise<IncidentReportDataExcerpt[]> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const report = await apiService.request('reports/incidents', 'GET');
    return report as unknown as IncidentReportDataExcerpt[];
  };

  static updateIncidentReport = async (
    correlationId: string,
    updates: Partial<IncidentReportData>
  ): Promise<IncidentReportData> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const updatedReport = await apiService.request(`reports/incidents/${correlationId}`, 'PUT', undefined, updates);
    return updatedReport as unknown as IncidentReportData;
  };

  static getIncidentNotifications = async (
    correlationId: string,
    mediums: NotificationMedium[]
  ): Promise<INotification[]> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const requestParams = new URLSearchParams();
    ApiUtility.appendArrayToParams(requestParams, 'mediums', mediums);
    const messages = await apiService.request(`reports/incidents/${correlationId}/notifications`, 'GET', requestParams);
    return messages as unknown as INotification[];
  };

  static sendIncidentNotification = async (
    correlationId: string,
    message: string,
    incidentId: string,
    mediumsToReturn: NotificationMedium[],
    recipients: { emailList: string[]; phoneNumberList: string[] },
    kioskId: string,
    notificationRequests: NewMessageRequest[]
  ): Promise<INotification[]> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const body = {
      message,
      incidentId,
      mediumsToReturn,
      recipients,
      kioskId,
      notificationRequests
    };
    const newMessage = await apiService.request(
      `reports/incidents/${correlationId}/notifications`,
      'POST',
      undefined,
      body
    );
    return newMessage as unknown as INotification[];
  };
}
