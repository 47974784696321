import React, { ReactNode } from 'react';
import styles from './SectionHeading.module.css';

interface ISectionHeading {
  heading: string;
  children?: ReactNode;
  // Heading levels (h2 to h6)
  level?: 2 | 3 | 4 | 5 | 6;
}

const SectionHeading: React.FC<ISectionHeading> = ({ heading, children, level = 2 }) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <div className={styles.SectionHeading}>
      <HeadingTag>{heading}</HeadingTag>
      {children}
    </div>
  );
};

export default SectionHeading;
