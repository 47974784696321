enum NotificationMedium {
  InstantMessage = 'instant-message',
  Email = 'email',
  SMS = 'sms',
  FileUpload = 'file-upload'
}

export enum CustomerFacingNotificationMedium {
  Email = NotificationMedium.Email,
  SMS = NotificationMedium.SMS
}

export default NotificationMedium;
