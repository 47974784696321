import { useCallback } from 'react';
import ReportsService from './ReportsService';
import IKioskHealthReport from './IKiosksHealthReport';
import IncidentReportData from '../../screens/Reports/IncidentReport/IncidentReportData';
import IncidentReportDataExcerpt from '../../screens/Reports/IncidentReport/IncidentReportDataExcerpt';
import IncidentResolutionStatus from '../../screens/Reports/IncidentReport/IncidentResolutionStatus';
import INotification from '../Notifications/INotification';
import NotificationMedium from '../Notifications/NotificationMedium';
import NewMessageRequest from '../../common/Notifications/NewMessageRequest';

const useReports = (): {
  getKiosksHealthReport: () => Promise<IKioskHealthReport[] | undefined>;
  getIncidentReport: (correlationId: string) => Promise<IncidentReportData | undefined>;
  getIncidentReports: () => Promise<IncidentReportDataExcerpt[] | undefined>;
  updateIncidentResolution: (
    correlationId: string,
    resolution: IncidentResolutionStatus
  ) => Promise<IncidentReportData | undefined>;
  getIncidentNotifications: (correlationId: string, mediums: NotificationMedium[]) => Promise<INotification[]>;
  sendIncidentNotification: (
    correlationId: string,
    message: string,
    incidentId: string,
    mediumsToReturn: NotificationMedium[],
    recipients: { emailList: string[]; phoneNumberList: string[] },
    kioskId: string,
    notificationRequests: NewMessageRequest[]
  ) => Promise<INotification[]>;
} => {
  const getKiosksHealthReport = useCallback(async () => {
    try {
      const report = await ReportsService.getKiosksHealthReport();
      return report;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getIncidentReport = useCallback(async (correlationId: string) => {
    try {
      const report = await ReportsService.getIncidentReport(correlationId);
      return report;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const getIncidentReports = useCallback(async () => {
    try {
      const report = await ReportsService.getIncidentReports();
      return report;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const updateIncidentResolution = useCallback(async (correlationId: string, resolution: IncidentResolutionStatus) => {
    try {
      const updatedReport = await ReportsService.updateIncidentReport(correlationId, { resolution });
      return updatedReport;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const getIncidentNotifications = useCallback(async (correlationId: string, mediums: NotificationMedium[]) => {
    try {
      const notifications = await ReportsService.getIncidentNotifications(correlationId, mediums);
      return notifications;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const sendIncidentNotification = useCallback(
    async (
      correlationId: string,
      message: string,
      incidentId: string,
      mediumsToReturn: NotificationMedium[],
      recipients: { emailList: string[]; phoneNumberList: string[] },
      kioskId: string,
      notificationRequests: NewMessageRequest[]
    ) => {
      try {
        const newNotification = await ReportsService.sendIncidentNotification(
          correlationId,
          message,
          incidentId,
          mediumsToReturn,
          recipients,
          kioskId,
          notificationRequests
        );
        return newNotification;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    []
  );

  return {
    getKiosksHealthReport,
    getIncidentReport,
    getIncidentReports,
    updateIncidentResolution,
    getIncidentNotifications,
    sendIncidentNotification
  };
};

export default useReports;
