import { ReactNode, useEffect, useState } from 'react';
import { useEntityManagementContext } from '../context/useEntityManagementContext';
import FieldRenderer from '../../../components/Form/FormBuilder/FieldRenderer';
import { FieldConfig, FieldType } from '../../../components/Form/FormBuilder/FieldConfig';
import IconLoading from '../../../components/ui/Icons/IconLoading';
import SubmitButton from '../../../components/Button/SubmitButton';
import styles from './EntityForm.module.css';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';
import validatePassword from '../../../utils/validation/validatePassword/validatePassword';
import ObjectUtility from '../../../utils/ObjectUtility/ObjectUtility';
import SectionHeading from '../../../components/Heading/SectionHeading/SectionHeading';
import FormConfig from '../../../components/Form/FormBuilder/FormConfig';

interface IEntityForm<T> {
  formConfig: FormConfig<T>;
  mode: 'create' | 'edit';
  initialEntity?: Partial<T> | null;
  redirectOnSubmitPath?: string;
  onSuccess?: (entity: T | undefined) => unknown;
  /**
   * Callback triggered whenever the form's state changes.
   * This can be used to respond to user input or to dynamically update the form's configuration.
   *
   * **Example use case**:
   * Update the list of organizations when the selected Operator changes.
   */
  onFieldChange?: (formState: Partial<T>) => void;
  children?: ReactNode;
}

const EntityForm = <T,>({
  formConfig,
  mode,
  initialEntity,
  redirectOnSubmitPath,
  onSuccess,
  onFieldChange,
  children
}: IEntityForm<T>) => {
  const { state, initializeEntity, handleFieldChange, createNew, saveChanges } = useEntityManagementContext<T>();
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const defaultGroupId = 'default';

  useEffect(() => {
    if (mode === 'create' && !state.formState && initialEntity) {
      initializeEntity?.({ ...initialEntity });
    }
  }, [mode, initialEntity, state.formState, initializeEntity]);

  useEffect(() => {
    if (state.formState && onFieldChange) {
      onFieldChange(state.formState);
    }
  }, [state.formState, onFieldChange]);

  if (mode === 'edit' && !state.entity && !state.loading) {
    return <p style={{ color: 'red' }}>No data found.</p>;
  }

  const fieldConfigWithCustomFields = formConfig.fields.flatMap((field) => {
    if (field.type === 'password') {
      return [
        field,
        {
          key: 'confirmPassword' as keyof T,
          required: field.required,
          type: 'password',
          labelOverride: 'Confirm Password'
        } as FieldConfig<T>
      ];
    }
    return [field];
  });

  const visibleFields = fieldConfigWithCustomFields.filter((field) => {
    const isVisible = !field.renderCondition || field.renderCondition(state.formState || {}) === true;
    return isVisible;
  });

  const groupedFields = visibleFields.reduce<Record<string, FieldConfig<T>[]>>((acc, field) => {
    const groupKey = field.groupId || defaultGroupId;
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(field);
    return acc;
  }, {});

  const validateForm = (): { formIsValid: boolean; errors: Record<string, string> } => {
    const errors: Record<string, string> = {};
    visibleFields.forEach((field) => {
      const value = ObjectUtility.getValueByDotNotatedPath<Partial<T>>(state.formState, field.key as string);
      if (field.required && (value === undefined || value === null || value === '')) {
        errors[field.key as string] = `${stringWithSpacesUppercase(String(field.key))} is required.`;
      }

      if (field.customValidation) {
        const validationResult = field.customValidation(value, state.formState || {});
        if (!validationResult.isValid) {
          errors[field.key as string] = validationResult.errorMessage || 'Invalid value';
        }
      }

      if (field.type === 'password') {
        const confirmPassword = ObjectUtility.getValueByDotNotatedPath<Partial<T>>(state.formState, 'confirmPassword');
        try {
          validatePassword(value as string);
          if (value !== confirmPassword) {
            errors[field.key as string] = 'Passwords do not match.';
            errors['confirmPassword'] = 'Passwords do not match.';
          }
        } catch (error) {
          errors[field.key as string] = error instanceof Error ? error.message : 'Invalid password.';
        }
      }
    });
    setValidationErrors(errors);
    return { formIsValid: Object.keys(errors).length === 0, errors };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { formIsValid, errors } = validateForm();
    if (!formIsValid) {
      console.warn(`Validation failed: ${JSON.stringify(errors)}`);
      return;
    }

    if (mode === 'create') {
      const newEntity = await createNew(redirectOnSubmitPath);
      if (onSuccess && newEntity) onSuccess(newEntity);
    } else {
      const changesSaved = await saveChanges();
      if (onSuccess && changesSaved) onSuccess(undefined);
    }
  };

  const handleChange = (type: FieldType, key: keyof T | string | 'confirmPassword', value: any) => {
    handleFieldChange(type, key, value);
    setValidationErrors((prevErrors) => {
      const { [key as string]: _, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const groupLabel = (groupId: string) =>
    formConfig.groups.find((g) => g.id === groupId)?.label || String(stringWithSpacesUppercase(groupId));

  return (
    <form className={styles.EntityForm} onSubmit={handleSubmit} noValidate>
      {state.loading && !state.unsavedChanges && <IconLoading width={20} height={20} />}
      {Object.entries(groupedFields).map(([group, fields]) => (
        <div key={group} className={styles.fieldGroup}>
          {group !== defaultGroupId && <SectionHeading heading={groupLabel(group)} level={3} />}
          {fields.map((field) => (
            <FieldRenderer<T>
              key={String(field.key)}
              field={field}
              value={ObjectUtility.getValueByDotNotatedPath<T>(state.formState, field.key as string) || ''}
              onChange={(key, value) => handleChange(field.type, key, value)}
              disabled={state.loading}
              error={validationErrors?.[field.key as string]}
              labelOverride={field.label}
              layout={field.layout}
            />
          ))}
        </div>
      ))}
      {children}
      <div className={styles.submit}>
        <SubmitButton disabled={state.loading || !state.unsavedChanges} label={mode === 'create' ? 'Create' : 'Save'} />
        {state.loading && state.unsavedChanges && <IconLoading width={20} height={20} />}
      </div>
      {state.error && <p style={{ color: 'red' }}>{state.error}</p>}
    </form>
  );
};

export default EntityForm;
