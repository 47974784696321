import { useAppSelector } from '../../../hooks/storeHooks';
import useOperatorData from '../../../services/Operators/useOperatorData';
import useOrganizationData from '../../../services/Organizations/useOrganizationData';
import IUser from '../../../services/User/IUser';
import UserRole from '../../../common/UserRole';
import { CompanyGeneralFieldConfig } from '../CompanyEdit/CompanyFieldConfig';
import ICompany from '../../../services/Company/ICompany';
import { useEffect, useMemo } from 'react';
import FormConfig from '../../../components/Form/FormBuilder/FormConfig';

export const useCompanyFormConfig = (formState?: Partial<ICompany>): FormConfig<ICompany> => {
  const user = useAppSelector<IUser>((store) => store.auth?.user);
  const { operators } = useOperatorData();
  const { organizations, fetchNewOrganizations } = useOrganizationData({
    operatorId: formState?.operator as unknown as string
  });

  useEffect(() => {
    if (formState?.operator) fetchNewOrganizations();
  }, [formState?.operator, formState?.organization, fetchNewOrganizations]);

  return useMemo(
    () =>
      CompanyGeneralFieldConfig({
        operators: user.role === UserRole.DropSuperAdmin ? operators : [],
        organizations: user.role === UserRole.DropSuperAdmin ? organizations : []
      }),
    [user.role, operators, organizations]
  );
};

export default useCompanyFormConfig;
